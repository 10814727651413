import React from "react";
import logo from "../assets/Z-Dev.png";
import Resume from "../assets/Artem_Zakunts_resume.docx";
export const Footer = () => {
  return (
    <div>
      <footer className=" shadow bg-[#0a192f] ">
        <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
          <div className="sm:flex sm:items-center sm:justify-between">
            <a
              href="/Home"
              className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse"
            >
              <img src={logo} className="h-10" alt="Flowbite Logo" />
              <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white"></span>
            </a>
            <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
              <li>
                <a
                  href="https://www.linkedin.com/in/artem-zakunts-588194201/"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:underline me-4 md:me-6 text-lg"
                >
                  LinkedIn
                </a>
              </li>
              <li>
                <a
                  href="https://github.com/ArtemZakunts"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:underline me-4 md:me-6 text-lg"
                >
                  GitHub{" "}
                </a>
              </li>
              <li>
                <a
                  href="mailto:zakuntsart@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:underline me-4 md:me-6  text-lg"
                >
                  Email
                </a>
              </li>
              <li>
                <a
                  href={Resume}
                  download="Artem_Zakunts_Resume"
                  className="hover:underline  text-lg"
                >
                  Resume
                </a>
              </li>
            </ul>
          </div>
          <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        </div>
      </footer>
    </div>
  );
};

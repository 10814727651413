import React from "react";
import Me from "../assets/me.png";

export const About = () => {
  return (
    <div name="about" className="w-full h-screen bg-[#0a192f] text-gray-300">
      <div className="flex flex-col justify-center items-center w-full h-full">
        <div className="max-w-[1000px] w-full px-4 grid grid-cols-2 gap-8">
          <div className="sm:text-right pb-8 ">
            <p className="font-semibold text-4xl inline border-b-4 border-[#6721EF]">
              About
            </p>
          </div>
          <div></div>
        </div>
        <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-20 px-4">
          <div className="sm:text-right text-4xl   ">
            <p className="font-semibold">
              Hi, I'm Artem, nice to meet you. Please take a look around.
            </p>
            <p className="text-lg mt-5">
              I'm deeply passionate about crafting engaging and efficient web
              applications that resonate with users. With a keen eye for detail
              and a love for innovation, I specialize in leveraging the latest
              front-end tools and technologies to bring ideas to life.
            </p>
          </div>
          <div className="flex justify-center items-center mb-16">
            <img src={Me} alt="me" className="lg:w-96 w-64 rounded-lg " />
          </div>
        </div>
        <div className="flex justify-center items-center"></div>
      </div>
    </div>
  );
};

import React from "react";
import Xtreme from "../assets/Xtreme.png";
import PMG from "../assets/PMG.png";
import Atl from "../assets/AtlAqua.png";
import KC from "../assets/KC.png";
import Chamb from "../assets/Chamb.png";
import AtHome from "../assets/AtHome.png";

export const Work = () => {
  return (
    <div name="work" className="bg-[#0a192f] md:h-screen text-gray-300">
      <div className="max-w-[1100px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-semibold inline border-b-4 text-gray-300 border-[#6721EF]  ">
            Work
          </p>
          <p className="py-6">Check out my some recent work</p>
        </div>

        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4 ">
          <div
            style={{ backgroundImage: `url(${Atl})` }}
            className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div"
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-2xl font-semibold text-white tracking-wider">
                Next.js Application
              </span>
              <div className="pt-8 text-center">
                <a
                  href="https://www.atl-aquatics.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-semibold text-lg">
                    View Site
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${Xtreme})` }}
            className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div"
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-2xl font-semibold text-white tracking-wider">
                Next.js Application
              </span>
              <div className="pt-8 text-center">
                <a
                  href="https://www.x-tremewatersports.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-semibold text-lg">
                    View Site
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${PMG})` }}
            className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div "
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-2xl font-semibold text-white tracking-wider">
                Next.js Application
              </span>
              <div className="pt-8 text-center">
                <a
                  href="https://000020-priority-metrics-group.vercel.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-semibold text-lg">
                    View Site
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${AtHome})` }}
            className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div "
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-2xl font-semibold text-white tracking-wider">
                Next.js Application
              </span>
              <div
                className="pt-8 text-center"
                target="_blank"
                rel="noreferrer"
              >
                <a
                  href="https://www.athomeandcompany.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-semibold text-lg">
                    View Site
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${KC})` }}
            className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div "
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-2xl font-semibold text-white tracking-wider">
                Next.js Application
              </span>
              <div className="pt-8 text-center">
                <a
                  href="https://www.kcbisonranch.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-semibold text-lg">
                    View Site
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${Chamb})` }}
            className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div "
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-100">
              <span className="text-2xl font-semibold text-white tracking-wider">
                Next.js Application
              </span>
              <div className="pt-8 text-center">
                <a
                  href="https://www.chamberlainschocolate.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-semibold text-lg">
                    View Site
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

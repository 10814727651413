import React from "react";
import HTML from "../assets/html.png";
import CSS from "../assets/css.png";
import Tailwind from "../assets/tailwind.png";
import JS from "../assets/javascript.png";
import ReactImg from "../assets/react.png";
import GitHub from "../assets/github.png";
import Figma from "../assets/small-icon_figma.png";
import Next from "../assets/next2.png";
export const Skills = () => {
  return (
    <div name="skills" className="bg-[#0a192f] text-gray-300 w-full h-screen">
      {/* Container */}
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div>
          <p className="text-4xl font-semibold inline border-b-4 border-[#6721EF]  ">
            Skills
          </p>
          <p className="py-4">These are technologies I've worked with</p>
        </div>

        <div className="w-full grid grid-cols-2 sm:grid-cols-4 gap-6  text-center py-8 ">
          <div className="shadow-lg  shadow-[#040c16] hover:scale-110 duration-500">
            <img src={HTML} alt="HTML" className="w-20 mx-auto" />
            <p className="my-4">HTML</p>
          </div>
          <div className="shadow-lg  shadow-[#040c16] hover:scale-110 duration-500">
            <img src={CSS} alt="CSS" className="w-20 mx-auto" />
            <p className="my-4">CSS</p>
          </div>
          <div className="shadow-lg  shadow-[#040c16] hover:scale-110 duration-500">
            <img src={Tailwind} alt="Tailwind" className="w-20 mx-auto" />
            <p className="my-4">TailwindCSS</p>
          </div>
          <div className="shadow-lg  shadow-[#040c16] hover:scale-110 duration-500">
            <img src={JS} alt="HTML" className="w-20 mx-auto" />
            <p className="my-4">JavaScript</p>
          </div>
          <div className="shadow-lg  shadow-[#040c16] hover:scale-110 duration-500">
            <img src={ReactImg} alt="React" className="w-20 mx-auto" />
            <p className="my-4">React.js</p>
          </div>
          <div className="shadow-lg  shadow-[#040c16] hover:scale-110 duration-500">
            <img src={Next} alt="GitHub" className="w-20 mx-auto" />
            <p className="my-4">Next.js</p>
          </div>
          <div className="shadow-lg  shadow-[#040c16] hover:scale-110 duration-500">
            <img src={GitHub} alt="GitHub" className="w-20 mx-auto" />
            <p className="my-4">GitHub</p>
          </div>
          <div className="shadow-lg  shadow-[#040c16] hover:scale-110 duration-500">
            <img src={Figma} alt="GitHub" className="w-20 mx-auto" />
            <p className="my-4">Figma</p>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import logo from "../assets/Z-Dev.png";
import { FaBars, FaTimes, FaGithub, FaLinkedin } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { useState } from "react";
import { Link } from "react-scroll";
import Resume from "../assets/Artem_Zakunts_resume.docx";

export const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <div className="fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#0a192f] text-gray-300 text-lg">
      <div>
        <a href="/Home">
          <img src={logo} alt="Logo" className="w-[200px] lg:m-5 m-7" />
        </a>
      </div>

      <ul className="hidden md:flex ">
        <li>
          <Link to="home" smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li>
          <Link to="about" smooth={true} duration={500}>
            About
          </Link>
        </li>
        <li>
          <Link to="skills" smooth={true} duration={500}>
            Skills
          </Link>
        </li>
        <li>
          <Link to="work" smooth={true} duration={500}>
            Work
          </Link>
        </li>
        <li>
          <Link to="work" smooth={true} duration={500}>
            Contact
          </Link>
        </li>
      </ul>

      {/*Hamburger*/}
      <div onClick={handleClick} className="md:hidden z-10">
        {!nav ? <FaBars /> : <FaTimes />}
      </div>
      {/* Mobile Menu */}
      <ul
        className={
          !nav
            ? "hidden"
            : "absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center"
        }
      >
        <li className="py-6 text-4xl">
          <Link onClick={handleClick} to="home" smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link onClick={handleClick} to="about" smooth={true} duration={500}>
            About
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link onClick={handleClick} to="skills" smooth={true} duration={500}>
            Skills
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link onClick={handleClick} to="work" smooth={true} duration={500}>
            Work
          </Link>
        </li>
        <li className="py-6 text-4xl">
          {" "}
          <Link onClick={handleClick} to="work" smooth={true} duration={500}>
            Contact
          </Link>
        </li>
      </ul>

      <div className="hidden lg:flex fixed flex-col top-[35%] left-0 ">
        <ul className="">
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-90px] hover:ml-[-10px] duration-300 ">
            <a
              className="flex justify-between items-center w-full text-gray-300 bg-blue-500 rounded-lg py-2 px-2"
              href="https://www.linkedin.com/in/artem-zakunts-588194201/"
              target="_blank"
              rel="noreferrer"
            >
              Linkedin <FaLinkedin size={40} />
            </a>
          </li>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-90px] hover:ml-[-10px] duration-300">
            <a
              className="flex justify-between items-center w-full text-gray-300 bg-[#333333] rounded-lg py-2 px-2"
              href="https://github.com/ArtemZakunts"
              target="_blank"
              rel="noreferrer"
            >
              GitHub <FaGithub size={40} />
            </a>
          </li>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-90px] hover:ml-[-10px] duration-300">
            <a
              className="flex justify-between items-center w-full text-gray-300 bg-indigo-700 rounded-lg py-2 px-2"
              href="mailto:zakuntsart@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              Email <HiOutlineMail size={40} />
            </a>
          </li>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-90px] hover:ml-[-10px] duration-300">
            <a
              className="flex justify-between items-center w-full text-gray-300 bg-[#565f69] rounded-lg py-2 px-2"
              href={Resume}
              download="Artem_Zakunts_Resume"
            >
              Resume <BsFillPersonLinesFill size={40} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-scroll";
import Lottie from "lottie-react";
import animationA from "../assets/Animation.json";

export const Home = () => {
  return (
    <div
      name="home"
      className="bg-[#0a192f] w-full h-screen flex items-center justify-center"
    >
      {/* {Container} */}
      <div className="max-w-[1000px] mx-auto px-8 text-center">
        {/* {Text} */}
        <div>
          <p className="text-3xl text-[#8892b0]">Hi, I'm</p>
          <h1 className="lg:text-7xl text-5xl text-[#6721EF] font-semibold">
            Artem Zakunts
          </h1>
          <p className="text-3xl text-[#8892b0] py-4">
            I'm a front-end developer
          </p>
        </div>
        <div className="flex flex-col items-center justify-center mt-3">
          <div style={{ width: "55%" }}>
            {animationA ? (
              <Lottie animationData={animationA} loop={true} />
            ) : (
              <p>Loading...</p>
            )}
          </div>
          <Link to="work" smooth={true} duration={500} offset={-50}>
            <button className="flex group items-center bg-[#333333] text-[#8892b0] rounded-lg px-6 py-2 hover:bg-gray-300 hover:text-gray-900 duration-300 mt-6">
              View Work
              <span className="group-hover:rotate-90 duration-300 ">
                <HiArrowNarrowRight
                  size={30}
                  className="ml-2 "
                  color="#6721EF"
                />
              </span>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
